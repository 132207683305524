import axios from 'axios'
import gql from 'graphql-tag'
import {createProvider} from "../../../vue-apollo"

const apollo = createProvider().defaultClient

const actions = {
    async getRealmToken(context) {
        try {
            let loginResponse = await axios.post('https://realm.mongodb.com/api/client/v2.0/app/nepse-qdrmx/auth/providers/api-key/login', {
                'key': 'bEPORVesWqjtCF9dT1x0ymYQzYhh9q4a0j4wHwDxX9JqPJwXyaF5aXNEaK5DkM57'
            })

            context.commit('setRealmToken', loginResponse.data.access_token)
            localStorage.setItem('apollo-token', loginResponse.data.access_token);
            console.log('login called')
        } catch (error) {
            console.log(error)
        }
    },

    async getTodayData(context, {startOfTheDay, endOfTheDay}) {
        try {
            let todayData = await apollo.query({
                query: gql`query ($startOfTheDay: DateTime!, $endOfTheDay: DateTime!){
                      dailyData ( limit:1000,
                      sortBy: SYMBOL_ASC,
                        query: {
                          date_gte: $startOfTheDay,
                          date_lte: $endOfTheDay
                        }){
                        name
                        market_price
                        pe_ratio
                        category
                        percentage_change
                        symbol
                        date
                        eps
                        eps_quarter
                      }
                    }`,
                variables: {
                    startOfTheDay: startOfTheDay,
                    endOfTheDay: endOfTheDay
                }
            })
            context.commit('setTodayData', todayData.data.dailyData)
        }
        catch (error) {
            console.log(error)
        }
    }
}

export default (actions)