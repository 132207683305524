<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <span class="mr-2">Hello Nepse</span>
      </div>

      <v-spacer></v-spacer>

      
    </v-app-bar>

    <v-main>
      <TodayData/>
    </v-main>
  </v-app>
</template>

<script>
import TodayData from './components/TodayData';

export default {
  name: 'App',

  components: {
    TodayData,
  },

  data: () => ({
    //
  }),
};
</script>
