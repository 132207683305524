<template>
    <div>
        <v-container>
            <v-card>
                <v-card-title v-if="!tableLoading">

                    <v-dialog ref="dialog"
                              v-model="modal"
                              :return-value.sync="date"
                              persistent
                              width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="date"
                                    label="Picker in dialog"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker
                                v-model="date"
                                scrollable
                                @input="$refs.dialog.save(date)">
                            <v-spacer></v-spacer>
                            <v-btn
                                    text
                                    color="primary"
                                    @click="modal = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dialog.save(date)">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>

                    <v-spacer></v-spacer>

                    <v-text-field
                            height="100%"
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details>

                    </v-text-field>
                </v-card-title>
                <v-card-title v-if="!tableLoading">
                    <v-select
                            v-model="selectedCategory"
                            :items="categories"
                            label="Category"

                            return-object
                    >

                    </v-select>
                    <v-spacer></v-spacer>
                    <v-select
                            v-model="value"
                            :items="headers"
                            label="Columns"
                            multiple
                            return-object
                    >
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                                <span>{{ item.text }}</span>
                            </v-chip>
                            <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                            >(+{{ value.length - 1 }} others)</span>
                        </template>
                    </v-select>
                </v-card-title>
                <v-data-table
                        :headers="selectedHeaders"
                        :items="dailyDataFiltered"
                        multi-sort
                        class="elevation-1"
                        :search="search"
                        :options="options"
                        :item-class="itemRowBackground"
                        loading-text="Loading... Please wait"
                        fixed-header
                        :loading="tableLoading"
                >
                    <template v-slot:item.symbol="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">

                                <span v-bind="attrs" v-on="on"><strong>{{ item.symbol }}</strong></span>

                            </template>
                            <span>{{item.name}}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.market_price="{ item }">
                        <span ><strong>{{ item.market_price }}</strong></span>
                    </template>
                    <template v-slot:item.eps="{ item }">
                        <span >{{ item.eps }} {{item.eps_quarter}}</span>
                    </template>
                    <!-- <template v-slot:body="{ items }">
                      <tbody>

                        <tr   :class="getColor(item.percentage_change)" v-for="(item) in items" :key="item.name">
                          <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">

                              <td v-bind="attrs" v-on="on"><strong>{{ item.symbol }}</strong></td>
                            </template>
                            <span>{{item.name}}</span>
                        </v-tooltip>

                          <td>{{ item.market_price }}</td>
                          <td>{{ item.category }}</td>
                          <td>{{ item.pe_ratio }}</td>


                        </tr>

                      </tbody>
                    </template> -->
                </v-data-table>
            </v-card>
        </v-container>
    </div>
</template>

<script>
    // import axios from 'axios'
    // import gql from 'graphql-tag'
    import todayDataStore from '../store/modules/share-list'
    import {mapGetters} from 'vuex'

    export default {
        data() {
            return {
                tableLoading: true,
                selectedCategory: '',
                selectedDate: '',
                value: [],
                selectedHeaders: [],
                options: {
                    itemsPerPage: -1
                },
                modal: '',
                search: '',
                token: '',
                loading: true,
                headers: [
                    {text: 'Company', value: 'symbol',},
                    {text: 'Name', value: 'name', align: ' d-none'},
                    {text: 'Market Price', value: 'market_price'},
                    {text: '% Change', value: 'percentage_change'},
                    {text: 'P/E Ratio', value: 'pe_ratio'},
                    {text: 'EPS', value:'eps'},
                    {text: 'Category', value: 'category', filterable: false},

                ],


            }
        },

        computed: {
            ...mapGetters('todayData', {
                dailyData: 'getTodayData',
            }),
            dailyDataFiltered: function () {
                return this.dailyData;
            },
            categories: function () {

                //let unique = this.dailyDataFiltered.filter((item, i, ar) => ar.indexOf(item.category) === i);
                //console.log(unique);
                //return unique;
                return ["Commercial Bank"]
            },
            date: {
                get: function () {
                    return this.selectedDate;
                },
                set: async function (dates) {
                    this.selectedDate = dates;
                    await this.getDataOfSpecificDate()
                }
            },
            startOfTheDay: function () {
                var today;
                if (this.selectedDate == '') {
                    today = new Date();

                } else {
                    today = new Date(this.selectedDate);
                }
                today.setUTCHours(0, 0, 0, 0);
                return today.toISOString();
            },
            endOfTheDay: function () {
                var today;
                if (this.selectedDate == '') {
                    today = new Date();
                } else {
                    today = new Date(this.selectedDate);
                }
                today.setUTCHours(23, 59, 59, 999);
                return today.toISOString();
            },
        },


        async created() {
            this.$store.registerModule('todayData', todayDataStore)

            await this.$store.dispatch('todayData/getRealmToken')

            await this.getDataOfSpecificDate()
            this.tableLoading = false

            var today = new Date();
            this.selectedDate = today.toISOString().split('T')[0];
            this.value = this.headers;


        },

        methods: {
            getColor(percentageChange) {
                if (percentageChange < 0) return 'table-red'
                else if (percentageChange == 0) return 'table-gray'
                else return 'table-green'
            },
            itemRowBackground(item) {
                return this.getColor(item.percentage_change)
            },
            async getDataOfSpecificDate() {
                await this.$store.dispatch('todayData/getTodayData', {startOfTheDay: this.startOfTheDay, endOfTheDay: this.endOfTheDay})
            }
        },
        watch: {
            value(val) {
                this.selectedHeaders = val;
            }
        },
    }
</script>
<style>
    .table-gray {
        background-color: #D1D5DB !important;
        border-color: #D1D5DB !important;
    }
    .table-green{
        background-color: #a7f3d0 !important;
        border-color: #a7f3d0 !important;
    }
    .table-red{
        background-color: #fca5a5 !important;
        border-color: #fca5a5 !important;
    }
    .theme--light.v-data-table {
        color: #333333;
    }
    .v-application .v-data-table.elevation-1{
        box-shadow: none ! important;
    }
    .v-sheet.v-card{
        box-shadow: 0 2px 20px 0 rgba(0,0,0,20%) ! important;
        margin-top: 40px;
    }
</style>
