const mutations = {
    setRealmToken(state, value) {
        state.realmToken = value
    },

    setTodayData(state, todayData) {
        state.todayData = todayData
    }
}

export default (mutations)